import { useAuthService } from './services/useAuthService';
import { useAuthStore } from '~/stores/auth.store';
import type { IGetLimitsResponse } from '~/interfaces/dto/player/response';
import type { IRestrictionPermissions } from '~/interfaces/dto/player/response';

type IActionType = 'deposit' | 'withdrawal' | 'gameplay' | 'responsible-gaming'; //future expansion

export const useCheckRestrictions = async (
  action?: IActionType,
  data?: any
) => {
  const auth = useAuthStore();
  const $authService = useAuthService();

  const locks: IGetLimitsResponse = await $authService.getUserLimits();

  //set to true and deny access based on most restrictive lock
  const updatedRestrictions: Ref<IRestrictionPermissions> = ref({
    allowedLogin: true,
    allowedWager: true,
    allowedPayout: true,
    allowedWithdrawal: true,
  });

  locks?.data?.restrictionResponses?.forEach((lock) => {
    const restrictions = lock.restrictionType.restrictionPermissions;
    //checks all properties in restrictionPermission i.e. allowedLogin etc.
    for (var key in restrictions) {
      if (restrictions[key] === false) updatedRestrictions.value[key] = false;
    }
  });

  auth.setRestrictionPermissions(updatedRestrictions.value);
};
