<script setup lang="ts">
import { InformationIcon } from '~/components/design-system/icons/index';

import { useSiteStore } from '~/stores/site.store';
import { useCheckRestrictions } from '~/composables/useCheckRestrictions';
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import { responsibleGamingTabsPassthrough } from '~/components/passthroughs/tabs';
import Exclusion from './restrictions/exclusion.vue';
import Limits from './restrictions/limits.vue';
import type { Modals } from '~/interfaces/dto/general/modals';

const { $t, $enabled } = useNuxtApp();
const site = useSiteStore();

const tabs = ref(0);

const limits = computed(() => [
  $enabled('myaccount.depositlimits'),
  $enabled('myaccount.wagerlimits'),
  $enabled('myaccount.losslimit'),
  $enabled('myaccount.sessionlimit'),
]);

const limitsEnabled = computed(() => limits.value.some((t) => t));

const defaultTab = computed(() => {
  if (
    $enabled('myaccount.coolingoffperiod') ||
    $enabled('myaccount.selfexclusion')
  )
    return 'Exclusion';
  if (limitsEnabled.value) return 'Limits';
});

function openInfo(modalName: Modals) {
  site.activateModal(`rG${modalName}Message`);
}

async function updateRestrictionStatus() {
  await useCheckRestrictions();
}

onMounted(() => {
  //TODO: check is RG has been edited in the last 24 hours if so display something?
});
</script>

<template>
  <div
    class="w-full sm:py-4 flex justify-center text-base-priority sm:max-w-[428px] mx-auto"
  >
    <div
      class="bg-layer-1 py-4 pt-2.5 flex flex-col gap-1 sm:rounded-xl w-full"
    >
      <div class="flex justify-between px-4 mb-2">
        <p class="my-0 font-bold text-lg">{{ $t('responsible-gambling') }}</p>
        <InformationIcon
          class="cursor-pointer"
          @click="openInfo(tabs.d_value)"
        />
      </div>
      <hr class="border-dark-900" />
      <div class="card">
        <Tabs :value="defaultTab" ref="tabs">
          <TabList
            v-if="limitsEnabled"
            :pt="responsibleGamingTabsPassthrough.tabList"
          >
            <Tab
              v-if="
                $enabled('myaccount.coolingoffperiod') ||
                $enabled('myaccount.selfexclusion')
              "
              value="Exclusion"
              :pt="responsibleGamingTabsPassthrough.tab"
            >
              {{ $t('exclusion') }}
            </Tab>
            <Tab value="Limits" :pt="responsibleGamingTabsPassthrough.tab">
              {{ $t('limits') }}
            </Tab>
          </TabList>
          <hr v-show="limitsEnabled" class="border-dark-900" />
          <TabPanels>
            <TabPanel
              v-if="
                $enabled('myaccount.coolingoffperiod') ||
                $enabled('myaccount.selfexclusion')
              "
              value="Exclusion"
            >
              <Exclusion @limitAdded="updateRestrictionStatus" />
            </TabPanel>
            <TabPanel value="Limits">
              <Limits @limitAdded="updateRestrictionStatus" />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
