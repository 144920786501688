import type { PassThrough } from 'primevue/ts-helpers';
import type { SelectButtonPassThroughOptions } from 'primevue/selectbutton';

export const selectButtonPassthrough: PassThrough<SelectButtonPassThroughOptions> =
  {
    root: {
      class: 'flex gap-2',
    },
    pcToggleButton: {
      label: {
        class: 'text-base-priority font-bold text-base',
      },
      root: ({ props }) => ({
        class: [
          'pseudo-button',
          {
            'bg-primary-blue-gradient': props.modelValue,
          },
        ],
      }),
    },
  };
