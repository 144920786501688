<script setup lang="ts">
import type { IAddLimitRequest as IAddLimitPayload } from '~/interfaces/dto/player/request';
import { useAuthService } from '~/composables/services/useAuthService';

import Select from 'primevue/select';
import Button from '~/components/design-system/button.vue';

import { useSiteStore } from '~/stores/site.store';
import { useAuthStore } from '~/stores/auth.store';

const emits = defineEmits(['limitAdded']);

const { $t, $enabled } = useNuxtApp();
const site = useSiteStore();
const auth = useAuthStore();
const $authService = useAuthService();
const router = useRouter();

const loader = ref(false);

const wagerDurationOptions = [
  { key: $t('daily'), value: '00000000-0000-0000-da7a-ff0000000293' },
  { key: $t('weekly'), value: '00000000-0000-0000-da7a-ff0000000292' },
  { key: $t('monthly'), value: '00000000-0000-0000-da7a-ff0000000291' },
];
const wagerLimitOptions = [10, 100, 500, 1000, 10000, 100000];

const sessionLimitOptions = [5, 15, 30, 60];

const wagerDuration = ref();
const wagerLimit = ref();
const sessionLimit = ref();

//TODO: Leaving at 1m for design visuals please change to 0
const currentLimit = ref(1000000);
const totalAccrued = ref(1000000);

onMounted(() => {
  //make GET user-limits call
  //0.const restrctions = response.restrictionResponses
  // 1.iterate restrcitions
  //  2.check restrictionType.restrictionTypeId includes wagerDurationOptions array GUIDs
  //   3. IF TRUE
  //     3.1 currentLimit.value = threshold;
  //     3.2 totalAccrued.value = progress;
});

async function setWagerLimit() {
  loader.value = true;
  const payload: IAddLimitPayload = {
    restrictionTypeId: wagerDuration.value,
    threshold: wagerLimit.value,
    thresholdType: 0,
    restrictionReason: 'Self Wager Limit',
    internalNote: 'jpc-frontend',
  };
  await $authService.addUserLimit(payload).then((data) => {
    if (data.isSuccessful) {
      emits('limitAdded');
      site.toggleSiteNotice({
        heading: $t('Cooling-off-period'),
        message: $t('succesfully-added-limit'),
        severity: 'success',
        enabled: true,
        timeout: 5000,
      });
      auth.toggleAccountModal(false);
      router.push('/');
    } else {
      //TODO: error messaging
    }
  });

  loader.value = false;
}

async function disableSessionLimit() {
  //internalLimit POST remove-limit ????
  // its internal so will need new X-API-Keys
  //iterate restrctions -> if restrictionTypeId === 00000000-0000-0000-da7a-ff0000000294
  // get restrcitionId and send payload to remove-limit
}

async function setSessionLimit() {
  loader.value = true;
  const payload: IAddLimitPayload = {
    restrictionTypeId: '00000000-0000-0000-da7a-ff0000000294',
    threshold: sessionLimit.value,
    thresholdType: 1,
    restrictionReason: 'Self Session Limit',
    internalNote: 'jpc-frontend',
  };
  await $authService.addUserLimit(payload).then((data) => {
    if (data.isSuccessful) {
      emits('limitAdded');
      site.toggleSiteNotice({
        heading: $t('Cooling-off-period'),
        message: $t('succesfully-added-limit'),
        severity: 'success',
        enabled: true,
        timeout: 5000,
      });
      auth.toggleAccountModal(false);
      router.push('/');
    } else {
      //TODO: error messaging
    }
  });

  loader.value = false;
}
</script>

<template>
  <LazyUserInterfaceGenericLoader v-if="loader" container override-positioning="!left-[100px]" />

  <div class="bg-layer-1 flex flex-col mx-auto w-full">
    <div v-if="$enabled('myaccount.wagerlimits')">
      <div class="flex justify-start px-4 py-2">
        <p class="my-0 font-bold">{{ $t('wager-limit') }}</p>
      </div>
      <hr class="border-dark-900" />
      <div class="grid grid-cols-2 px-4 py-3 gap-2">
        <p class="my-0">{{ $t('duration') }}</p>
        <Select
          :options="wagerDurationOptions"
          option-label="key"
          option-value="value"
          v-model="wagerDuration"
          :placeholder="wagerDurationOptions[0].key"
          class="w-min justify-self-end"
        />
        <p class="my-0">{{ $t('current-limit') }}</p>
        <p
          class="my-0 font-bold text-right"
          v-html="useFormatCurrency(currentLimit)"
        />
        <p class="my-0">{{ $t('total-accrued') }}</p>
        <p
          class="my-0 font-bold text-right"
          v-html="useFormatCurrency(totalAccrued)"
        />
        <p class="my-0">{{ $t('new-wager-limit') }}</p>
        <Select
          :options="wagerLimitOptions"
          v-model="wagerLimit"
          placeholder="0"
        />
      </div>
      <Button
        type="primary"
        class="mx-4 justify-center mb-3"
        @click="setWagerLimit()"
      >
        {{ $t('set-your-limit') }}
      </Button>
    </div>

    <hr class="border-dark-900" />
    <div v-if="$enabled('myaccount.sessionlimit')">
      <div class="flex justify-start px-4 py-2">
        <p class="my-0 font-bold">{{ $t('jpc-session-limit') }}</p>
      </div>
      <hr class="border-dark-900" />
      <div class="mx-4">
        <Select
          :options="sessionLimitOptions"
          v-model="sessionLimit"
          :placeholder="$t('minutes')"
          class="w-full my-2"
        />
        <div class="flex gap-4">
          <Button
            type="secondary"
            class="w-full justify-center"
            @click="disableSessionLimit()"
          >
            {{ $t('disable') }}
          </Button>
          <Button
            type="primary"
            class="w-full justify-center"
            @click="setSessionLimit()"
          >
            {{ $t('set-your-period') }}
          </Button>
        </div>
      </div>
    </div>

    <LazyUserInterfaceGenericLoader v-if="loader" container />
  </div>
</template>
