<script setup lang="ts">
import type { IAddLimitRequest as IAddLimitPayload } from '~/interfaces/dto/player/request';
import SelectButton from 'primevue/selectbutton';
import { selectButtonPassthrough } from '~/components/passthroughs/selectbutton';
import { useAuthService } from '~/composables/services/useAuthService';
import Button from '~/components/design-system/button.vue';
import { useSiteStore } from '~/stores/site.store';
import { useAuthStore } from '~/stores/auth.store';

const emits = defineEmits(['limitAdded']);

const { $t } = useNuxtApp();
const site = useSiteStore();
const auth = useAuthStore();
const $authService = useAuthService();
const router = useRouter();

const loader = ref(false);

const restrictionTypeId = '00000000-0000-0000-da7a-ff0000000289';
const period: Ref<number> = ref();
const limit: Ref<number> = ref(0);
const errorMessage = ref('');
const step = ref(0);

const periodOptions = [
  { text: $t('days'), value: 2 },
  { text: $t('weeks'), value: 3 },
  { text: $t('months'), value: 4 },
];

const isValidLimit = computed(() => {
  if (limit?.value < 1) return false;
  switch (period?.value) {
    case 2:
      return limit.value < 91;
    case 3:
      return limit.value < 14;
    case 4:
      return limit.value < 4;
    default:
      return limit.value < 91;
  }
});

function formatPeriod() {
  var formattedPeriod = periodOptions.find(
    (item) => item.value === period.value
  ).text;
  if (limit.value === 1) {
    formattedPeriod = formattedPeriod.slice(0, -1);
  }
  return formattedPeriod;
}

async function submitSelfLock() {
  loader.value = true;
  const payload: IAddLimitPayload = {
    restrictionTypeId: restrictionTypeId,
    threshold: limit.value,
    thresholdType: period.value,
    restrictionReason: 'Self Exclusion',
    internalNote: 'jpc-frontend',
  };
  await $authService.addUserLimit(payload).then((data) => {
    if (data.isSuccessful) {
      emits('limitAdded');
      site.toggleSiteNotice({
        heading: $t('Cooling-off-period'),
        message: $t('succesfully-added-limit'),
        severity: 'success',
        enabled: true,
        timeout: 5000,
      });
      auth.toggleAccountModal(false);
      router.push('/');
    } else {
      //TODO: error messaging
    }
  });

  loader.value = false;
}
</script>

<template>
  <LazyUserInterfaceGenericLoader v-if="loader" container override-positioning="!left-[100px]" />
  <div>
    <!-- Step 1 -->
    <div class="px-4 pt-2.5 flex flex-col gap-2" v-if="step === 0">
      <p>
        <strong>{{ $t('cooling-off-period') }}</strong>
        ({{ $t('up-to-3-months') }})
      </p>
      <label class="text-sm" for="limit">{{ $t('limit') }}</label>
      <input
        id="limit"
        v-model="limit"
        type="number"
        min="1"
        step="1"
        max="90"
        class="form-bg-input std-border rounded-lg pl-2 py-1"
      />

      <label class="text-sm" for="periodSelection">
        {{ $t('period') }}
      </label>
      <SelectButton
        id="periodSelection"
        v-model="period"
        :options="periodOptions"
        option-label="text"
        option-value="value"
        :allowEmpty="false"
        :pt="selectButtonPassthrough"
      />

      <small v-if="errorMessage" class="p-error text-xs">
        {{ $t(errorMessage) }}
      </small>

      <Button
        type="primary"
        class="w-full justify-center"
        :disabled="!isValidLimit || !(period >= 0)"
        @click="step++"
      >
        {{ $t('set-your-period') }}
      </Button>
    </div>

    <!-- Step 2 -->
    <div
      v-else
      class="bg-layer-1 pt-2.5 flex flex-col gap-2 sm:rounded-xl mx-auto w-full"
    >
      <div class="flex justify-between px-4">
        <p class="my-0 font-bold">{{ $t('cooling-off-period') }}</p>
      </div>
      <hr class="border-dark-900" />
      <div class="px-4 flex flex-col gap-2">
        <p>{{ $t('cooling-off-period-body-1') }}</p>
        <span class="flex">
          <p class="mr-1">{{ $t('your-acc-will-be-locked-for') }}</p>
          <strong class="whitespace-pre">
            {{ limit + ' ' }} {{ $t(formatPeriod()) }}
          </strong>
        </span>
        <p>{{ $t('cooling-off-period-body-1') }}</p>
      </div>
      <div class="flex w-full justify-center px-4 gap-3">
        <Button
          type="primary"
          class="w-full justify-center"
          @click="submitSelfLock()"
        >
          {{ $t('confirm') }}
        </Button>
        <Button type="secondary" class="w-full justify-center" @click="step--">
          {{ $t('back') }}
        </Button>
      </div>
    </div>
  </div>
</template>
